import { getFormProps, getInputProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { useFetcher, useSearchParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { LinkButton } from '../button/link-button'
import { AuthContainerHeader } from '../container/auth-container'
import { ErrorList } from '../error-list/error-list'
import { Button } from '~/components/button/button'
import { StatusButton } from '~/components/button/status-button'
import { Field } from '~/components/forms/forms'
import { Link } from '~/components/internal-link/Internal-link'
import { useClientLang } from '~/utils/i18n.utils'
import { useIsFetching } from '~/utils/misc'
import { forgotPasswordSchema } from '~/types/auth'
import { type action } from '~/routes/auth/forgot-password'
import { HoneypotInputs } from 'remix-utils/honeypot/react'

export function ForgotPasswordForm({
	handlePageChange,
}: {
	handlePageChange?: (page: number) => void
}) {
	const fetcher = useFetcher<typeof action>()
	const isPending = useIsFetching(fetcher)
	const [searchParams] = useSearchParams()
	const { t } = useTranslation()
	const [form, fields] = useForm({
		id: 'forgot-password',
		constraint: getZodConstraint(forgotPasswordSchema),
		lastResult: fetcher?.data?.result,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: forgotPasswordSchema })
		},
		defaultValue: {
			email: searchParams.get('email'),
		},
		shouldRevalidate: 'onInput',
	})
	const lang = useClientLang().lngShort

	return (
		<>
			{fetcher?.data?.result.status === 'success' ? (
				<>
					<AuthContainerHeader
						title={t('reset_password_check_email')}
						text={t('auth_reset_password')}
						icon="check-badge"
					/>
					{handlePageChange === undefined && (
						<div className="mt-6 text-center">
							<LinkButton variant="secondary" to="/">
								{t('action_back_to_homepage')}
							</LinkButton>
						</div>
					)}
				</>
			) : (
				<>
					<AuthContainerHeader
						title={
							<>
								{t('auth_forgotten_password')}
								<span className="text-secondary">?</span>
							</>
						}
						text={t('auth_forgotten_password_description')}
					/>

					<fetcher.Form
						method="POST"
						{...getFormProps(form)}
						action="/forgot-password"
						className="mx-auto w-full max-w-sm"
					>
						<HoneypotInputs />
						<input name="lang" value={lang} hidden readOnly />
						<div>
							<Field
								labelProps={{
									htmlFor: fields.email.id,
									children: t('form_label_email'),
								}}
								inputProps={{
									autoFocus: true,
									...getInputProps(fields.email, { type: 'email' }),
								}}
								errors={fields.email.errors}
							/>
						</div>
						<ErrorList errors={form.errors} id={form.errorId} />

						<div className="mt-6">
							<StatusButton
								className="w-full"
								status={isPending ? 'pending' : 'idle'}
								type="submit"
								disabled={isPending}
							>
								{t('reset_password_title')}
							</StatusButton>
						</div>
					</fetcher.Form>

					<div className="mx-auto mt-6 text-center">
						{handlePageChange !== undefined ? (
							<Button
								variant="link"
								type="button"
								onClick={() => handlePageChange(0)}
								className="hover:underline max-md:hidden"
							>
								{t('action_back_to_login')}
							</Button>
						) : (
							<Link className="text-center hover:underline" to="/login">
								{t('action_back_to_login')}
							</Link>
						)}
					</div>
				</>
			)}
		</>
	)
}
